import { NavLink } from "react-router-dom";

export default function Nav() {
    return (
        <nav>
            <NavLink to="/" end>
                Filmliste
            </NavLink>
            <NavLink to="/tilføj">Tilføj film</NavLink>
        </nav>
    );
}
