import { useEffect, useState } from "react";

export default function PostForm({ savePost, post }){
    const [navn, setNavn] = useState("");
    const [se, setSe] = useState("");
    const [tilfoj, setTilfoj] = useState(0);
    const [type, setType] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");

useEffect(() => {
    if (post) {
        setNavn(post.navn);
        setSe(post.se);
        setTilfoj(post.tilfoj);
        setType(post.type);
        console.log(type);
    } 
}, [post]);

async function handleSubmit(e) {
    e.preventDefault();
    const formData = {
        navn: navn,
        se: se,
        tilfoj: tilfoj,
        type: type
    }

   const validForm = formData.navn && formData.se && formData.tilfoj && formData.type;
   if (validForm) {
    savePost(formData);
   } else {
    setErrorMessage("Please, fill in all fields.");
   }
}

return (
    <form onSubmit={handleSubmit}>
         {post ? (
         <input type="hidden" name="tilfoj" value={tilfoj} />
        )
         : 
        (
       <label>
        Tilføjet af:    
         <input type="text" name="tilfoj" placeholder="Hvem tilføjer?" onChange={e => setTilfoj(e.target.value)} />
        </label>
        )}
        <label className="type">
            film/serie? 
            <select id="type" name="type" value={type} onChange={e => setType(e.target.value)}>
                <option value="film">vælg en mulighed</option>
                <option value="film">Film</option>
                <option value="serie">Serie</option>
            </select>
            <br></br><br></br>
     </label>
     <label>
         Navnet på film/serie?
         <input type="text" name="navn" value={navn} placeholder="Navnet på film/serie?" onChange={e => setNavn(e.target.value)} />
     </label>
     <label>
         Hvem vil gerne se med?
         <input type="text" name="se" value={se} placeholder="Hvem vil gerne se med?" onChange={e => setSe(e.target.value)} />
     </label>
    
     <p className="text-error">{errorMessage}</p>
     <button type="submit">Save</button>
     </form>
 );

}
