import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function PostCard({post}) {
  const navigate = useNavigate();
  const [film,SetFilm] =useState(false);
  const [serie,SetSerie] =useState(false);

  function handleClick() {
    navigate(`posts/${post.id}`);
  }

  function Test2(){
    const someCheckbox = document.getElementById('serie');
  
  someCheckbox.addEventListener('change', e => {
    if(e.target.checked === true) {
      console.log("Checkbox is checked - boolean value: ", e.target.checked);
      SetSerie(true);
      console.log(serie);
    }
  if(e.target.checked === false) {
      console.log("Checkbox is not checked - boolean value: ", e.target.checked)
      SetSerie(false);
      console.log(serie);
    }
  });}
    function Test(){
      const someCheckbox = document.getElementById('test');
    
    someCheckbox.addEventListener('change', e => {
      if(e.target.checked === true) {
        console.log("Checkbox is checked - boolean value: ", e.target.checked);
        SetFilm(true);
        console.log(film);
      }
    if(e.target.checked === false) {
        console.log("Checkbox is not checked - boolean value: ", e.target.checked)
        SetFilm(false);
        console.log(film);
      }

  });}
  Test();
  Test2();

  return (
  <div className={post.type==="film"? (film===true ? "film":"nofilm"): (serie===true ? "serie":"noserie")} onClick={handleClick}>
  <span className="ident">tilføjet af: {post.tilfoj}</span>
  <section className="card">
    <div className="film">
      <p>{post.type}: {post.navn}</p>
    </div>
    <div className="seer">
      <p>Hvem vil gerne se: {post.se}</p>
    </div>
  </section>
</div>
  );
}
